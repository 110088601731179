<template>
  <div class="news-list">
    <div v-for="news in newsList" :key="news.id">
      <news-card
        :key="news.newsId"
        :news="news"
        :show-opened="true"
        :show-read="true"
        @read="markAsRead(news.newsId)"
      />
      <br />
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/news/NewsCard.vue";

import newsService from "~/service/newsService";

export default {
  name: "NewsList",
  components: { NewsCard },
  data() {
    return {
      newsList: []
    };
  },
  computed: {
    showDialog() {
      return this.newsList.length > 0;
    }
  },
  methods: {
    async fetchNews() {
      this.newsList = await newsService.list();
      this.newsList = this.newsList.filter(news => news.cancelDate == null);
      // news example
      // this.newsList = [
      //   {
      //     newsId: 9,
      //     newsTypeId: 2,
      //     title: "Nuovo ordine disponibile per il rider",
      //     descr: "Nuovo ordine disponibile per il rider",

      //     documents: [],

      //     read_date: null
      //   }
      // ];
    },
    async markAsRead(newsId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("dashboard.removeNews")
      });
      if (res) {
        await newsService.cancel(newsId);
        this.fetchNews();
      }
    }
  },
  mounted() {
    this.fetchNews();
  }
};
</script>
