<template>
  <div class="gift-card-container">
    <v-card
      class="gift-card default--text"
      :class="mode"
      :flat="mode == 'checkout'"
    >
      <v-row no-gutters>
        <v-col :cols="mode == 'full' ? 12 : 2" :sm="mode == 'full' ? 4 : 1">
          <router-link
            :to="link"
            :aria-label="`Coupon ${name}`"
            :title="`Coupon ${name}`"
          >
            <img
              v-if="src"
              :src="src"
              :alt="`Coupon ${name}`"
              class="rounded-l w-100"
              cover
            />
            <div
              v-else
              class="certificate-card d-flex flex-column justify-space-around align-center green lighten-4 pa-4"
              :class="cssClass"
              @click.prevent.stop="handleClick"
              outlined
            >
              <div class="default-icon-container pa-8">
                <v-icon color="#000000">$gift</v-icon>
              </div>

              <div v-if="badgeLabel" class="badge-tag">
                {{ badgeLabel }}
              </div>
              <v-icon v-if="icon" :alt="badgeLabel">
                ${{ icon.substring(0, icon.length - 4) }}
              </v-icon>
              <span v-else-if="badgeValue" class="d-block badge-value">
                {{ badgeValue }}<em class="small" v-html="badgeUnit"></em>
              </span>
            </div>
          </router-link>
        </v-col>

        <v-col
          :cols="mode == 'full' ? 12 : 10"
          :sm="mode == 'full' ? 8 : 11"
          class="d-flex flex-column pa-4"
        >
          <div
            v-if="!hideEndDate && !showListAvailableCertificates"
            class="expiration-date text-caption grey lighten-3 mb-2 pa-1"
            v-html="
              `<span class='font-weight-bold text-uppercase'>${$t(
                'checkout.expires'
              )}</span>: ${$t('benefits.endDate')} 
                ${$dayjs(giftCertificate.endDate).format('DD/MM')}`
            "
          ></div>
          <div class="text-h4 font-weight-bold" v-html="name"></div>
          <div class="text-body-2 description" v-html="description"></div>
          <div
            class="text-body-2 content"
            v-html="giftCertificate?.categoryProposal?.content"
          ></div>
          <div
            class="text-body-2 mt-2"
            v-if="mode == 'full' && listUsedCertificates.length > 0"
          >
            Utilizzato in questi ordini:
            <v-chip
              v-for="used in listUsedCertificates"
              :key="used.orderId"
              :to="{ name: 'Order', params: { orderId: used.orderId } }"
              class="mr-2"
              label
              small
            >
              <span class="text-caption">#{{ used.orderId }}</span>
            </v-chip>
          </div>
          <div class="text-body-2 mt-2" v-if="showListAvailableCertificates">
            <i>Buoni utilizzabili:</i><br />
            <div
              v-for="available in listAvailableCertificates"
              :key="available.userGiftCertificateId"
              class="available-certificates-list py-1 d-flex"
            >
              <div class="available-certificates-content py-1">
                <span class="text-body-2 font-weight-bold">
                  {{ name
                  }}<template v-if="available.amount"
                    >:&nbsp;{{ $n(available.amount, "currency") }}
                  </template>
                </span>
                <div
                  v-if="available.endDate"
                  class="expiration-date text-caption grey lighten-3"
                  v-html="
                    `<span class='font-weight-bold text-uppercase'>${$t(
                      'checkout.expires'
                    )}</span>: ${$t('benefits.endDate')} ${$dayjs(
                      available.endDate
                    ).format('DD/MM')}`
                  "
                ></div>
              </div>
              <v-spacer />
              <v-btn
                v-if="available.cartId != cart.cartId && !available.descrExtra"
                small
                depressed
                color="primary"
                @click="addUserGift(available.userGiftCertificateId)"
              >
                {{ $t("checkout.giftCodeUseNow") }}
              </v-btn>
              <div v-else>
                <v-btn
                  v-if="!available.descrExtra"
                  small
                  depressed
                  color="primary"
                  outlined
                  @click="remove(available.userGiftCertificateId)"
                >
                  {{ $t("checkout.giftCodeRemove") }}
                </v-btn>
              </div>
            </div>
          </div>

          <v-spacer />

          <v-card-actions class="pa-0 mt-2 space-between align-center">
            <div v-if="isEanGiftcode">
              <v-btn
                outlined
                large
                depressed
                color="primary"
                @click="() => (dialogEan = !dialogEan)"
              >
                {{ $t("profile.gifts.eanGiftcodeBtn") }}
              </v-btn>
              <v-dialog v-model="dialogEan" width="50vw">
                <template v-slot:activator="{ on, attrs }">
                  <div v-html="activator" v-bind="attrs" v-on="on"></div>
                </template>

                <v-card class="d-flex flex-column align-center justify-center">
                  <v-card-title
                    class="d-block w-100 text-center text-h5 grey lighten-2"
                  >
                    {{ $t("profile.gifts.eanGiftcodeTitle") }}
                  </v-card-title>
                  <barcode
                    :value="giftCertificate?.userGiftCertificates[0].descrExtra"
                    :options="{ format: 'CODE128', width: 380, height: 150 }"
                    class="px-2 align-self-center ean-giftcode"
                  >
                    Error
                  </barcode>
                </v-card>
              </v-dialog>
            </div>
            <div v-else></div>
            <v-btn
              v-if="mode == 'full' && link"
              outlined
              :large="mode == 'full'"
              :small="mode == 'checkout'"
              depressed
              color="primary"
              :to="link"
            >
              {{ btnLabel }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="isAssignable && userGiftCertificateId != null"
              :large="mode == 'full'"
              :small="mode == 'checkout'"
              depressed
              outlined
              color="primary"
              @click="remove(userGiftCertificateId)"
              >{{ $t("checkout.giftCodeRemove") }}
            </v-btn>
            <v-btn
              v-else-if="isAssignable"
              :large="mode == 'full'"
              :small="mode == 'checkout'"
              depressed
              color="primary"
              @click="add"
              >{{ $t("checkout.giftCodeUseNow") }}</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<style lang="scss">
.ean-giftcode {
  .vue-barcode-element {
    height: auto;
    max-width: 100%;
    width: 350px;
  }
}
.gift-card-container {
  .certificate-card {
    background-color: var(--v-primary-lighten4) !important;
    // .default-icon-container {
    //   border-radius: 100%;
    //   border: 2px solid black;
    // }
  }
  .expiration-date {
    width: fit-content;
  }
}
.gift-card {
  &.checkout {
    border-bottom: 1px solid #707070;
    border-radius: 0px;
    padding-bottom: 4px;
    .body-column {
      padding-left: 8px;
    }
    .certificate-card {
      min-height: 0;
      .v-icon {
        font-size: 20px;
      }
    }
  }
  &.full {
    border-radius: 4px;
    .body-column {
      padding: 20px !important;
    }
    .certificate-card {
      min-height: 100px;
      .v-icon {
        font-size: 40px;
      }
    }
  }
  .available-certificates-list:not(:last-child) {
    border-bottom: 1px solid var(--v-grey-lighten3);
  }
  .v-image {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .certificate-card {
    border-color: var(--v-grey-lighten3);
    border-radius: 8px 0px 0px 8px;
    height: 100%;
    min-height: 200px;
    .icon-circle {
      padding: 10px;
      border: 3px solid var(--v-default-base);
      border-radius: 50%;
      .v-icon {
        color: var(--v-primary-lighten1);
      }
    }
    .promo-body {
      text-align: center;
      min-width: 100px;
      color: white;
      background-color: var(--v-primary-lighten1);
      border-radius: 25px;
      padding: 5px;
      font-weight: bold;
    }
    .content {
      text-align: center;
    }
    &.primary {
      background-color: var(--v-primary-lighten2) !important;
      border-color: var(--v-primary-lighten1);
      .icon-circle {
        border-color: var(--v-primary-lighten1);
        .v-icon {
          color: var(--v-primary-lighten1);
        }
      }
      .promo-body {
        background-color: var(--v-primary-lighten1);
      }
    }
    &.secondary {
      background-color: var(--v-secondary-lighten1) !important;
      border-color: var(--v-secondary-base);
      .icon-circle {
        border-color: var(--v-secondary-base);
        .v-icon {
          color: var(--v-secondary-base);
        }
      }
      .promo-body {
        color: $text-color;
        background-color: var(--v-secondary-lighten1);
      }
    }
    &.warning {
      background-color: var(--v-warning-lighten3) !important;
      border-color: var(--v-warning);
      .icon-circle {
        border-color: var(--v-warning-lighten1);
        .v-icon {
          color: var(--v-warning-lighten1);
        }
      }
      .promo-body {
        color: $text-color;
        background-color: var(--v-warning-lighten1);
      }
    }
  }
}
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";

export default {
  name: "GiftCard",
  mixins: [giftCertificate],
  props: {
    mode: {
      type: String,
      default: "full"
    }
  },
  data() {
    return {
      dialogEan: false
    };
  },
  computed: {
    showListAvailableCertificates() {
      return !this.isAssignable && this.listAvailableCertificates.length > 0;
    },
    isEanGiftcode() {
      return (
        this.giftCertificate?.userGiftCertificates &&
        this.giftCertificate?.userGiftCertificates.length > 0 &&
        this.giftCertificate?.userGiftCertificates[0].descrExtra
      );
    }
  },
  methods: {
    formatDate(date) {
      return this.$dayjs(date).format("D MMMM YYYY");
    }
  }
};
</script>
