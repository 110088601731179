<template>
  <div>
    <div v-if="!giftList || giftList.length == 0" class="font-weight-bold">
      {{ $t("giftList.noGiftCertificates") }}
    </div>
    <div v-else>
      <GiftCard
        v-for="gift in giftList"
        :key="gift.userGiftCertificateId"
        :giftCertificate="gift"
        :mode="mode"
        class="mb-7"
        @add="$emit('reload')"
        @remove="$emit('reload')"
      />
    </div>
  </div>
</template>
<style lang="scss">
.gift-list {
  h2 {
    font-size: 20px;
  }
  .widget {
    min-height: 360px;
    background-size: contain;
  }
}
</style>
<script>
import GiftCard from "./GiftCard.vue";
export default {
  components: { GiftCard },
  name: "GiftList",
  props: {
    title: {
      type: String,
      required: false
    },
    giftList: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: "full"
    }
  }
};
</script>
