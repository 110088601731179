<template>
  <div class="profile-wall-container" v-if="category">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <CategoryTitle :category="category" />

    <div class="hello mt-3">
      {{ $t("dashboard.hello") }}
      <strong>{{ cart.user.person.firstName }}</strong>
    </div>

    <v-divider class="my-3" />
    <!--

    -->
    <NewsList class="mt-5" />
    <v-divider class="my-3" />

    <category-block
      :target="category"
      position="position3"
      :container="false"
      class="category-block category-block-3"
    />
    <category-block
      :target="category"
      position="position4"
      class="category-block category-block-4"
    />

    <div class="d-flex flex-column flex-sm-row my-8">
      <v-btn
        color="primary"
        large
        depressed
        min-width="300"
        :to="{ name: 'ProfileUpdate' }"
        class="mr-sm-5 mb-5 mb-sm-0"
        >{{ $t("dashboard.goToProfileBtn") }}</v-btn
      >
      <v-btn
        color="primary"
        large
        depressed
        :to="{ name: 'Addresses' }"
        min-width="300"
        >{{ $t("dashboard.goToAddressesBtn") }}</v-btn
      >
    </div>

    <v-card v-if="orders && orders.length > 0" class="mt-12" outlined>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <h3>{{ $t("dashboard.orderSectionTitle") }}</h3>
          <v-btn link text :to="{ name: 'Orders' }">
            <span class="text-underline">{{
              $t("dashboard.showAllOrderBtn")
            }}</span
            ><v-icon>$arrowForward</v-icon>
          </v-btn>
        </div>
        <OrdersTableContainer :orders="orders" :customPageSize="4" />
      </v-card-text>
    </v-card>

    <v-card class="mt-12" outlined>
      <v-card-text>
        <GiftList :title="$t('dashboard.giftCodeTitle')" :mode="'checkout'" />
      </v-card-text>
    </v-card>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.profile-wall-container {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import NewsList from "@/components/profile/NewsList.vue";
//import NewsList from "@/views/profile/NewsList.vue";
import OrdersTableContainer from "@/components/orders/OrdersTableContainer.vue";
import GiftList from "@/components/gift/GiftList.vue";

import categoryMixins from "~/mixins/category";

import OrderService from "~/service/orderService";

import { mapState } from "vuex";

export default {
  name: "ProfileWall",
  components: {
    CategoryTitle,
    NewsList,
    OrdersTableContainer,
    GiftList
  },
  mixins: [categoryMixins],
  data() {
    return {
      orders: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    config() {
      return this.category.categoryBlocks[0];
    }
  },
  methods: {
    async getOrders() {
      let res = await OrderService.getOrders(null, 5, false, true, true, false);
      this.orders = res.orders;
    }
  },
  async mounted() {
    this.getOrders();
  }
};
</script>
